import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TimVideoVaultSliderLoggedIn() {
    const [setSliderRef] = useState(null);

    const sliderSettings = {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        touchThreshold: 100,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const timvideovaultNav = [
        {
            hrefLink: "/",
            hrefText: "welcome",
        },
        {
            hrefLink: "/just-added?content=Scenes&sort=Newest&page=1",
            hrefText: "scenes",
        },
        {
            hrefLink: "most-watched?content=Movies&sort=MostWatched&page=1",
            hrefText: "movies",
        },
        {
            hrefLink: "/nsoriginals?content=Scenes&sort=Newest&page=1",
            hrefText: "nsoriginals",
        },
        {
            hrefLink: "/stars",
            hrefText: "men",
        },
        {
            hrefLink: "/themes",
            hrefText: "themes",
        },
        {
            hrefLink: "/studios",
            hrefText: "studios",
        },
        {
            hrefLink: "https://treasureislandmedia.com/members/helpdesk",
            hrefText: "help",
        },
        {
            hrefLink: "https://treasureislandmedia.com/members/member",
            hrefText: "account",
        },
        {
            hrefLink: "https://www.timvideovault.com/sso?logout&redir=aHR0cHM6Ly90cmVhc3VyZWlzbGFuZG1lZGlhLmNvbQ",
            hrefText: "logout",
        },
    ];

    return (
        <div className="content">
            <Slider ref={setSliderRef} {...sliderSettings}>
                {timvideovaultNav.map((navlink, index) => (
                    <div key={index} className="navlink">
                        <div className="text-info">
                            <a
                                href={navlink.hrefLink}
                                className="nav-sub-link"
                                rel="noopener noreferrer"
                            >
                                <span>{navlink.hrefText}</span>
                            </a>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
