import { env } from './env';

if (!env.REACT_APP_JOIN_NOW_URL) {
  console.error('REACT_APP_JOIN_NOW_URL is missing in env.js file!');
}

let envJoinUrl = env.REACT_APP_JOIN_NOW_URL.endsWith('/') ?
  env.REACT_APP_JOIN_NOW_URL :
  env.REACT_APP_JOIN_NOW_URL + '/';

// get value from storage/url after nats code is changed
export const createJoinLinkFromLastCode = (code) => `${envJoinUrl}${code}/join`;

export const links = {
  billingAndSupport: `mailto:${env.REACT_APP_SUPPORT_EMAIL}`,
  joinNow: 'https://treasureislandmedia.com/tvvsignup',
  instagram: 'https://www.instagram.com/officialnakedsword/?hl=en',
  live: 'https://nakedswordlive.com/',
  twitter: 'https://twitter.com/nakedswordcom',
  webmasters: env.REACT_APP_WEBMASTERS_URL,
  webMastersSignIn: `${env.REACT_APP_WEBMASTERS_URL}/signup/password.php`,
  timmain: 'https://treasureislandmedia.com',
  timlogin: 'https://treasureislandmedia.com/members/member'
};