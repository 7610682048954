import React, { useEffect, useState } from 'react';

import { authLogin, authLogout, authMe } from '../../../services/auth-service/auth.service';
import { removeUserLocalData, setUserLocalData } from '../../../services/users-service/users.service';
import { cleanupToken, isUserLoggedIn, saveToken } from '../../../services/token-service/token.service';
import { LoadingController } from '../../../controllers/loading-controller/loading.controller';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { ROUTES } from '../../../services/navigation/navigation.routes.js';

import SSOLoggedInToWelcome from "./SSOLoggedInToWelcome.js";
import { ErrorMessage } from '../../../components/ErrorBoundary/ErrorBoundary';
import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo.js';

import './SSOPage.scss';

/*
1. when user get to the /sso page, if has ?creds=name&password params, will be logged in and redirected to home page
2. if user has ?redir param, will be redirected to redir param url
*/

const queryKeys = {
    credentials: 'creds', // encoded with encodeURIComponent() and window.btoa (base64)
    redirection: 'redir', // encoded with encodeURIComponent() and window.btoa (base64)
    logout: 'logout',
};

const SSOPage = () => {
    const [error, setError] = useState(false);

    useEffect(() => {
        const html = document.documentElement;
        if (html) {
            html.style.overflow = 'auto';
            html.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        const getSearchParams = (url, params) => {
            try { // window.atob or decodeURIComponent might throw an exception  
                const p = url.get(params);
                const base64decode = window.atob(p);
                const URIdecode = decodeURIComponent(base64decode);

                return URIdecode;
            } catch (err) {
                setError(true);
                console.error('Parameters are not encoded correctly!');
            }
        };

        const isValidUrl = urlString => {
            try {
                return Boolean(new URL(urlString));
            }
            catch (err) {
                console.log(err);
                return false;
            }
        }

        const redirectOutside = (path = '', time = 500) => {
            // window.location.replace(path); // triggers ErrorBoundary  
            let url;
            let pathUrl; // to avoid redirection loop

            if (isValidUrl(path)) {
                pathUrl = new URL(path).origin + '/';
            }

            if (pathUrl === document.referrer) {
                url = document.referrer;
            } else if (path.includes('http')) {
                url = path;
            } else {
                url = 'https://treasureislandmedia.com/';
            }

            const timeoutID = setTimeout(() => {
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('style', 'display:none');
                link.setAttribute('rel', 'nofollow noreferrer');
                document.body.appendChild(link);
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                });
                link.dispatchEvent(clickEvent); // or link.click() triggers adBlocker sometimes 
                document.body.removeChild(link);
                clearTimeout(timeoutID);
            }, time);
        };

        const locationSearch = window.location.search;
        if (locationSearch) {
            const allParams = new URLSearchParams(locationSearch);
            LoadingController.showLoadingMask();

            if (allParams.has(queryKeys.credentials)) {
                const credentials = getSearchParams(allParams, queryKeys.credentials);
                const credParams = new URLSearchParams(credentials);
                const userName = credParams.get('name');
                const password = credParams.get('password');

                if (!!userName && !!password) {
                    if (!isUserLoggedIn()) {
                        authLogin(userName, password)
                            .then((resp = {}) => {
                                const { expiresIn, jwt } = resp.data?.data || {};
                                saveToken(jwt, expiresIn);
                                UsersController.notifyUserStateChange();
                            })
                            .then(() => {
                                authMe()
                                    .then((resp = {}) => {
                                        const { data } = resp.data || {};
                                        setUserLocalData(data);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                                    .finally(() => {
                                        LoadingController.removeLoadingMask();
                                    });
                            })
                            .then(() => {
                                if (allParams.has(queryKeys.redirection)) {
                                    const redirect = getSearchParams(allParams, queryKeys.redirection);
                                    redirectOutside(redirect, 0);
                                }
                            })
                            .catch(err => {
                                setError(true);
                                LoadingController.removeLoadingMask();
                                console.error(err);
                            });
                    }
                }
            }

            if (allParams.has(queryKeys.redirection) && !allParams.has(queryKeys.credentials)) {
                const redirect = getSearchParams(allParams, queryKeys.redirection);
                redirectOutside(redirect);
            }

            if (allParams.has(queryKeys.redirection) && isUserLoggedIn()) {
                const redirect = getSearchParams(allParams, queryKeys.redirection);
                redirectOutside(redirect);
            }

            if (allParams.has(queryKeys.logout)) {
                LoadingController.showLoadingMask();
                authLogout()
                    .then(() => {
                        cleanupToken();
                        removeUserLocalData();
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        LoadingController.removeLoadingMask();
                        window.location.href = `/${ROUTES.specialOffers}`;
                    });
            }
        }
    }, []);

    const renderView = () => {
        if (error) return <ErrorMessage />;

        return (
            <>
                <ApplicationLogo notLink />
                <SSOLoggedInToWelcome />
            </>
        );
    };

    return (
        <div className='SSOPage'>
            {renderView()}
        </div>
    );
};

SSOPage.displayName = 'SSOPage';

export default SSOPage;