import React, { Component } from "react";
import PropTypes from "prop-types";
import TimTab from "./TimTab";

class TimTabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.navLabel,
    };
  }

  onClickTabItem = (tab) => {
    const { activeTab } = this.state;

    if (tab === activeTab) {
      this.setState({ activeTab: 'Video Vault' });
    } else {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { navLabel } = child.props;

            return (
              <TimTab
                activeTab={activeTab}
                key={navLabel}
                navLabel={navLabel}
                navId={navLabel}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.navLabel !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default TimTabs;
