import React, { useState, useEffect } from 'react';

const TimFooterDynamicPull = () => {
	const [footerContent, setFooterContent] = useState('');

	useEffect(() => {
		// Ensure the Bootstrap CSS is loaded for this component
		const bootstrapStyle = document.createElement('link');
		bootstrapStyle.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
		bootstrapStyle.rel = 'stylesheet';
		document.head.appendChild(bootstrapStyle);

		fetch('https://treasureislandmedia.com/timfooter')
			.then(response => response.text())
			.then(html => {
				setFooterContent(html);
			})
			.catch(error => {
				console.error('Error fetching footer:', error);
				setFooterContent('Failed to load footer content.');
			});

		return () => {
			// Remove the Bootstrap CSS when the component unmounts to avoid potential conflicts elsewhere
			document.head.removeChild(bootstrapStyle);
		};
	}, []);

	return (
		<footer dangerouslySetInnerHTML={{ __html: footerContent }} />
	);
};

export default TimFooterDynamicPull;