import React from 'react';
import TimFooterDynamicPull from "./TimFooterDynamicPull.js";
import ToggleTimFooterOnMobile from "./ToggleTimFooterOnMobile.js";


export default function TimFooter() {

    return (

        <div className="TimSiteFooter">
            <ToggleTimFooterOnMobile>
            <TimFooterDynamicPull />
            </ToggleTimFooterOnMobile>
            <div className="clearfix"></div>
        </div>

    );
}