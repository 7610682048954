import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TimVideoVaultSlider() {
	const[ setSliderRef ] = useState(null);

	const sliderSettings = {
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		touchThreshold: 100,
		infinite: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	const timvideovaultNav = [
		{
			hrefLink: "/",
			hrefText: "welcome",
		},
		{
			hrefLink: "https://treasureislandmedia.com/tvvsignup", 
			hrefText: "join",
		},
		{
			hrefLink: "https://treasureislandmedia.com/members/member",
			hrefText: "login",
		},
		{
			hrefLink: "/just-added?content=Scenes&sort=Newest&page=1",
			hrefText: "scenes",
		},
		{
			hrefLink: "/most-watched?content=Movies&sort=MostWatched&page=1",
			hrefText: "movies",
		},
		{
			hrefLink: "/stars",
			hrefText: "men",
		},
		{
			hrefLink: "/themes",
			hrefText: "themes",
		},
		{
			hrefLink: "/studios",
			hrefText: "studios",
		},
	];

	return (
		<div className="content">
			<Slider ref={setSliderRef} {...sliderSettings}>
				{timvideovaultNav.map((navlink, index) => (
					<div key={index} className="navlink">
						<div className="text-info">
							<a
								href={navlink.hrefLink}
								className="nav-sub-link"
								rel="noopener noreferrer"
							>
								<span>{navlink.hrefText}</span>
							</a>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
}