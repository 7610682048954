import React, { useEffect } from "react";

function BuyMoviesSlider() {
	useEffect(() => {
		window.location.href =
			"https://tim.store/";
	}, []);

	return (
		<div>

		</div>
	);
}

export default BuyMoviesSlider;