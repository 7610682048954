import React, { useEffect } from "react";

function TimNewsSlider() {
	useEffect(() => {
		window.location.href =
			"https://tim.news";
	}, []);

	return (
		<div>

		</div>
	);
}

export default TimNewsSlider;
